<template>
  <div>
    <!--begin::Login-->
    <div class="login login-4 d-flex flex-row-fluid" :class="activeFormClass" id="kt_login">
      <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
        <div class="login-form text-center p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="../../../../public/media/logos/logo-letter-4.png" class="max-h-75px" alt=""/>
            </a>
          </div>
          <!--end::Login Header--> {{ errors }}
          <div role="alert" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
            <div class="alert-text">
              {{ $t('login.invalid_user') }}
            </div>
          </div>
          <!--begin::Login Sign in form-->
          <div class="login-signin">
            <div class="mb-20">
              <h3>{{ $t('login.header') }}</h3>
              <div class="text-muted font-weight-bold">{{ $t('login.header_text') }}</div>
            </div>
            <b-form class="form" @submit.stop.prevent="onSubmit" id="kt_login_signin_form">
              <div class="form-group mb-5">
                <b-form-group
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <b-form-input
                      class="form-control h-auto form-control-solid py-4 px-8"
                      id="example-input-1"
                      name="example-input-1"
                      v-model="$v.form.email.$model"
                      :state="validateState('email')"
                      aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Email is required and a valid email address.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="form-group mb-5">
                <b-form-input
                    :placeholder="$t('messages.password')"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="password"
                    id="example-input-2"
                    name="example-input-2"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
                ></b-form-input>
              </div>
              <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                <a href="#" id="kt_login_forgot" class="text-muted text-hover-primary"
                   @click="showForm('forgot')">{{ $t('login.forgot_password') }} </a>
              </div>
              <button id="kt_login_signin_submit" ref="kt_login_signin_submit"
                      class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" @click="showForm('signin')">
                {{ $t('messages.login') }}
              </button>
            </b-form>
          </div>
          <!--end::Login Sign in form-->
          <!--begin::Login forgot password form-->
          <div class="login-forgot">
            <div class="mb-20">
              <h3>{{ $t('login.forgot_password_header') }}</h3>
              <div class="text-muted font-weight-bold">{{ $t('login.forgot_password_header_text') }}</div>
            </div>
            <form class="form" id="kt_login_forgot_form" method="post">
              <div class="form-group mb-10">
                <input class="form-control form-control-solid h-auto py-4 px-8" type="text"
                       :placeholder="$t('messages.email')" name="email" autocomplete="off"/>
              </div>
              <div class="form-group d-flex flex-wrap flex-center mt-10">
                <button id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">
                  {{ $t('messages.send') }}
                </button>
                <button id="kt_login_forgot_cancel" type="button"
                        class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2" @click="showForm('signin')">
                  {{ $t('messages.cancel') }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Login forgot password form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {mapState} from "vuex";
import {LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
import KTUtil from "@/assets/js/components/util";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      activeFormClass: 'login-signin-on',
      // Remove this dummy login info
      form: {
        email: "admin@demo.com",
        password: "demo"
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    loginLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo-letter-13.png";
    }
  },
  methods: {
    showForm(id) {
      let form = 'kt_login_' + id + '_form';
      this.activeFormClass = 'login-' + id + '-on'
      KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
    },

    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // send login request
        this.$store
          .dispatch(LOGIN, { username, password })
          // go to which page after successfully login
            .then((response) => {
              let menu = localStorage.getItem('menu');
              let firstItem = _.head(_.values(JSON.parse(menu)))
              if (firstItem.hasOwnProperty('menu')) {
                this.$router.push({path: firstItem.menu.link})
              } else {
                this.$router.push({name: "dashboard"})
              }
            }).catch((error) => {
            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        })
    },
  },
  mounted() {
    let version = process.env.VUE_APP_VERSION;
    let localVersion = localStorage.getItem('version');
    if (!localVersion || version !== localVersion) {
      window.localStorage.clear();
      localStorage.setItem('version', version);
      location.reload();
    }
    this.showForm('signin');
  }
};
</script>
